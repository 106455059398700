<template>
  <div>

    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b">
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Daftar <b>Remunerasi</b> yang ada di {{ clinic_name }}
      </div>
      <b-button
        squared
        variant="success"
        @click="$router.push('/masters/remunerations/add')"
        v-if="btn">Tambah</b-button>
    </b-alert>

    <!-- Component List -->
    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>

            <!-- Filter -->
            <div class="row align-items-center justify-content-end mt-n3">
              <div class="col-md-3">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text squared>
                      <b-icon-search></b-icon-search>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    type="text"
                    v-model="filter.employee_name"
                    placeholder="Cari Berdasar Nama Karyawan"
                    @keyup="filterByEmployee"></b-form-input>
                </b-input-group>
              </div>
            </div>

            <!-- Table -->
            <b-table
              striped
              hover
              class="mt-3"
              :items="items"
              :fields="fields"
              :style="'white-space: nowrap'"
              responsive>
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'actions' ? '15%' : '' }" />
              </template>
              <template #cell(ammount)="data">
                {{ parseInt(data.item.ammount).toLocaleString('id-ID') }}
                {{ data.item.ammount >= 100 ? "(Rp)" : "(%)" }}
              </template>
              <template #cell(actions)='data'>
                <b-button
                  size="sm"
                  class="mr-1 btn-info"
                  v-b-tooltip.hover
                  title="Detail"
                  @click="$router.push({ path: '/masters/remunerations/detail/' + data.item.id })"> <i class="fas fa-eye px-0"></i> </b-button>
                <b-button
                  size="sm"
                  class="mr-1 btn-success"
                  v-b-tooltip.hover
                  title="Ubah"
                  @click="$router.push({ path: '/masters/remunerations/edit/' + data.item.id })"
                  v-if="btn"> <i class="fas fa-edit px-0"></i> </b-button>
                <b-button
                  size="sm"
                  class="btn-danger"
                  v-b-tooltip.hover
                  title="Hapus"
                  @click="deleteData(data.item.id)"
                  v-if="btn"> <i class="fas fa-trash px-0"></i> </b-button>
              </template>
            </b-table>
            <b-pagination
              v-if="items.length != 0"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              @page-click="pageOnClick"
              class="mt-4"></b-pagination>
          </template>
        </Card>
      </div>
    </div>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import { debouncer } from "@/core/modules/Helper.js"
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"
import moment from 'moment'

export default {

  name: "List",

  components: {
    Card
  },

  data() {
    return {
      // Filter
      filter: {
        employee_name: ''
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 0,
      // Table Head
      fields: [
        {
          key: "number",
          label: '#',
          sortable: true,
        },
        {
          key: "employee_name",
          label: "Nama Karyawan",
          sortable: true,
        },
        {
          key: "remuneration_type_name",
          label: "Tipe Remunerasi",
          sortable: true,
        },
        {
          key: "ammount",
          label: "Total",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
      // user access
      btn: false,
      clinic_name: window.configuration == null ? "Medhop" : window.configuration.clinic_name
    }
  },

  methods: {

    pageOnClick(page) {
      this.currentPage = page
      this.pagination()
    },

    async pagination() {
      let filterParams = `&employee_name=${this.filter.employee_name}`
      let response = await module.paginate('remunerations', `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      let pagination = response.meta.pagination
      this.totalRows = pagination.total

      let a, b
      b = this.perPage * (this.currentPage - 1) + 1
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a
      }
      this.items = response.data
    },

    filterByEmployee() {
      debouncer(() => { this.pagination() }, 500)
    },

    handleDate(date) {
      return moment(date).format('DD-MM-YYYY')
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete('remunerations/' + id)
      // If Deleted
      if (result) {
        this.pagination()
      }
    },

    setActiveButton() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)

      let a
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1802") {
          this.btn = true
        }
      }
    },

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Remunerasi" }])
    // Get Data
    this.pagination()
    this.setActiveButton()
  },

}

</script>
